<template>
  <center-content>
    <form @submit.prevent="reset">
      <base-card footer="bg-e0">
        <template #header>إستعادة</template>
        <template #body>
          <div class="form-group text-left">
            <label>البريد الإلكتروني</label>
            <form-input
              id="email"
              type="email"
              placeholder="البريد الإلكتروني"
              autofocus
            ></form-input>
          </div>
          <div class="form-group text-left">
            <label>كلمة المرور</label>
            <form-input
              id="password"
              type="password"
              placeholder="كلمة المرور"
            ></form-input>
          </div>
          <div class="form-group text-left mb-0">
            <label>تأكيد كلمة المرور</label>
            <form-input
              id="password_confirmation"
              type="password"
              placeholder="تأكيد كلمة المرور"
            ></form-input>
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-dark nr-block mb-1">حفظ</button>
        </template>
      </base-card>
    </form>
  </center-content>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import FormInput from "@/components/Forms/FormInput";
import CenterContent from "@/components/UI/CenterContent";

export default {
  components: { CenterContent, FormInput, BaseCard },
  computed: {
    token() {
      return new URL(document.URL).searchParams.get("token");
    },
  },
  methods: {
    async reset(e) {
      if (!!this.token) {
        const formData = new FormData(e.target);
        formData.set("token", this.token);
        const response = await http.send("reset-password", formData);
        if (http.responseAccepted(response)) {
          if (response.data.updated) {
            await this.$router.replace("/login");
            http.popupMessage("success", messages.passwordChanged);
          } else {
            http.popupMessage("error", messages.linkError);
          }
        }
      } else {
        await this.$router.replace("/");
      }
    },
  },
};
</script>
